<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
      <el-tab-pane label="下载中" name="first">
        <el-table
            :data="downLoadingData"
            style="width: 100%"
            :height="ULHeight"
        >
          <el-table-column
              fixed
              prop="fileName"
              label="名称">
          </el-table-column>
          <el-table-column
              prop="taskId"
              label="任务标识"/>
          <el-table-column
              prop="size"
              label="大小">
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <el-tag type="warning">{{ scope.row.dfState === 1 ? "下载中" : "" }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="rate"
              label="速率">
          </el-table-column>
          <el-table-column
              prop="plan"
              label="进度">
          </el-table-column>
          <el-table-column
              prop="creationTime"
              label="创建时间">
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :pager-count="5"
            :page-size="downLoadingPage.pageSize"
            layout="prev, pager, next"
            :total="downLoadingPage.total">
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="添加任务" name="third">
        <el-form ref="form" :model="addForm" label-width="">
          <el-form-item label="名称">
            <el-input v-model="addForm.fileName"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="addForm.fileUrl"></el-input>
          </el-form-item>
          <el-form-item label="并发">
            <el-input type="number" v-model="addForm.threadCount"></el-input>
          </el-form-item>
          <el-row type="flex" class="row-bg" justify="space-around">
            <el-col :lg="1" :xs="6">
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="全部任务" name="second">
        <el-table
            :data="allData"
            style="width: 100%" :height="ULHeight">
          <el-table-column
              fixed
              prop="fileName"
              label="名称">
          </el-table-column>
          <el-table-column
              prop="taskId"
              label="任务标识"/>
          <el-table-column
              prop="size"
              label="大小">
          </el-table-column>
          <el-table-column
              label="状态">
            <template slot-scope="scope">
              <el-tag type="warning" v-if=" scope.row.dfState === 1">下载中</el-tag>
              <el-tag type="success" v-else-if="scope.row.dfState === 2">已完成</el-tag>
              <el-tag type="danger" v-else-if=" scope.row.dfState === 3">异常</el-tag>
              <el-tag type="primary" v-else-if=" scope.row.dfState === 0">等待中</el-tag>
              <el-tag type="info" v-else-if=" scope.row.dfState === 5">暂停</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="creationTime"
              label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                  size="mini" :disabled="scope.row.dfState === 1"
                  type="danger"
                  @click="deleteTask(scope.row)">删除
              </el-button>
              <el-button
                  size="mini"
                  type="danger" v-if="scope.row.dfState === 1"
                  @click="terminatedTask(scope.row)">终止
              </el-button>
              <el-button
                  v-if="scope.row.dfState === 2"
                  size="mini"
                  @click="play(scope.row)">播放
              </el-button>
              <el-button
                  size="mini" v-if="scope.row.dfState === 0"
                  type="info"
                  @click="changeState(scope.row, 5)">暂停
              </el-button>
              <el-button
                  size="mini" v-if="scope.row.dfState === 3 || scope.row.dfState === 5"
                  type="primary"
                  @click="changeState(scope.row, 0)">恢复
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :pager-count="5"
            :page-size="allDataPage.pageSize"
            layout="prev, pager, next"
            :total="allDataPage.total">
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DownFile from "../../../request/expand/downFile";

export default {
  name: "file_index",
  data() {
    return {
      ask: new DownFile(),
      ULHeight: (window.innerHeight - 16 * 2 - 32 - 44 - 40),
      controller: null,
      activeName: 'third',
      downLoadingData: [],
      downLoadingPage: {
        "pageNum": 1,
        "pageSize": 3,
        "state": 1,
        "total": 0
      },
      allData: [],
      allDataPage: {
        "pageNum": 1,
        "pageSize": 10,
        "state": -1
      },
      addForm: {
        "fileName": "",
        "fileUrl": "",
        "threadCount": 10,
        "total": 0
      },
      currentTab: "first"
    }
  },
  mounted() {
  },
  methods: {
    terminatedTask(row){
      this.ask.terminatedTask({
        "taskId": row.taskId,
      }).then(res => {
        this.allDataPage.pageNum = 1
        this.ask.getDataS(this.allDataPage).then(res => {
          this.allDataPage.total = res.total
          this.allData = res.list
        })
      })
    },

    changeState(row, state) {
      this.ask.updateState({
        "taskId": row.taskId,
        "state": state
      }).then(res => {
        this.allDataPage.pageNum = 1
        this.ask.getDataS(this.allDataPage).then(res => {
          this.allDataPage.total = res.total
          this.allData = res.list
        })
      })
    },
    deleteTask(row) {
      this.ask.deleteTask({
        "taskId": row.taskId
      }).then(res => {
        this.allDataPage.pageNum = 1
        this.ask.getDataS(this.allDataPage).then(res => {
          this.allDataPage.total = res.total
          this.allData = res.list
        })
        this.$message({
          showClose: true,
          message: '删除成功!',
          type: 'success'
        });
      })
    },
    play(row) {
      this.$router.push({
        name: 'm3u8Play',
        query: {
          url: `/df/downFile/${row.taskId}/index.m3u8`
        }
      })
    },
    handleCurrentChange(val) {
      if (this.currentTab === 'first') {
        this.stopSSE()
        this.downLoadingPage.pageNum = val
        this.ask.getDataS(this.downLoadingPage).then(res => {
          if (res.list.length !== 0) {
            this.downLoadingPage.total = res.total
            res.list.forEach(item => {
              item['rate'] = '000';
              item['plan'] = 0
            })
            this.downLoadingData = res.list
            let newList = res.list.map(item => ({path: item.taskId, size: item.size}));
            this.fetchAsk(newList);
          } else {
            this.downLoadingPage.tota = res.total
            this.downLoadingData = res.list
          }
        })
      } else if (this.currentTab === 'second') {
        this.allDataPage.pageNum = val
        this.ask.getDataS(this.allDataPage).then(res => {
          this.allDataPage.total = res.total
          this.allData = res.list
        })
      }
    },
    onSubmit() {
      this.ask.addTask(this.addForm).then(res => {
        if (res.code === 'success') {
          this.addForm.fileName = ''
          this.addForm.fileUrl = ''
          this.$message({
            showClose: true,
            message: '恭喜你，新增成功',
            type: 'success'
          });
        }
      })
    },
    handleClick(tab, event) {
      this.currentTab = tab['_props']['name']
      if (tab['_props']['name'] === 'second') {
        this.ask.getDataS(this.allDataPage).then(res => {
          this.allData = res.list
          this.allDataPage.total = res.total
        })
        this.stopSSE();
      } else if (tab['_props']['name'] === 'first') {
        this.ask.getDataS(this.downLoadingPage).then(res => {
          if (res.list.length !== 0) {
            this.downLoadingPage.total = res.total
            res.list.forEach(item => {
              item['rate'] = '000';
              item['plan'] = 0
            })
            this.downLoadingData = res.list
            let newList = res.list.map(item => ({path: item.taskId, size: item.size}));
            this.fetchAsk(newList);
          } else {
            this.downLoadingPage.tota = res.total
            this.downLoadingData = res.list
          }
        })
      } else {
        this.stopSSE()
      }
    },
    fetchAsk(newList) {
      let controller = new AbortController();
      let signal = controller.signal;
      this.controller = controller
      fetch('/see/fileV/readDownPlan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newList),
        signal: signal  // 将信号对象添加到fetch请求中
      })
          .then(response => {
            // 检查是否收到成功响应
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            // 处理 SSE
            this.handleSSE(response);
          })
          .catch(error => {
            console.error('请求错误:', error);
          });
    },
    handleSSE(response) {
      let this_ = this;
      const reader = response.body.getReader();
      let buffer = '';

      // 处理每个数据块的函数
      function processStream({done, value}) {
        if (done) {
          console.log('连接已关闭');
          return;
        }
        const chunk = new TextDecoder('utf-8').decode(value);
        buffer += chunk;
        // 处理分隔的消息
        while (buffer.includes('\n\n')) {
          const separatorIndex = buffer.indexOf('\n\n');
          const message = buffer.substring(0, separatorIndex);
          buffer = buffer.substring(separatorIndex + 2);
          const data = message.replaceAll("\n", "").replaceAll("\t", "").replaceAll("data:", "")
          let obj = JSON.parse(JSON.parse(data))
          let index = this_.downLoadingData.findIndex(element => element['taskId'] === obj['taskId']);
          if (index !== -1) {
            this_.downLoadingData[index]['rate'] = obj.rate;
            this_.downLoadingData[index]['plan'] = obj.plan;
          }
        }
        // 继续读取下一个结果
        return reader.read().then(processStream);
      }

      // 读取第一个结果
      reader.read().then(processStream).catch(error => {
        console.error('读取错误:', error);
      });
    },
    stopSSE() {
      try {
        this.controller.abort();
      } catch (e) {
        console.log("大橙同学")
      }
    }
  }
}
</script>

<style scoped>
.el-pagination {
  margin-top: 5px;
  text-align: center;
}
</style>